/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sectionIds } from '../Redux/dynamicContents';

const randomNumber = (num) => Math.floor(Math.random() * num);

const usePageOptimization = () => {
  const state = useSelector((s) => s);
  const template = state?.template?.data[0];
  const optimizationEnabled = template?.optimization;
  const dispatch = useDispatch();

  const heroOptimization = template?.heroOptimization;
  const submitOptimization = template?.submitOptimization;
  const ourTechOptimization = template?.ourTechOptimization;
  const trustOptimization = template?.trustOptimization;
  const subscribeOptimization = template?.subscribeOptimization;
  const heroNum =
    localStorage.getItem('hero') || randomNumber(heroOptimization?.ref?.length);
  const submitNum =
    localStorage.getItem('submit') ||
    randomNumber(submitOptimization?.ref?.length);
  const ourTechNum =
    localStorage.getItem('ourTech') ||
    randomNumber(ourTechOptimization?.ref?.length);
  const trustNum =
    localStorage.getItem('trust') ||
    randomNumber(trustOptimization?.ref?.length);
  const subscribeNum =
    localStorage.getItem('subscribe') ||
    randomNumber(subscribeOptimization?.ref?.length);

  // set section ids
  useEffect(() => {
    dispatch(
      sectionIds([
        {
          sectionName: 'hero',
          sectionId: heroOptimization?.ref?.[heroNum]?._id,
          threshold: heroOptimization?.threshold,
          optimizationParent: 'heroOptimization',
          optimized: heroOptimization?.choosen || false,
        },
        {
          sectionName: 'submit',
          sectionId: submitOptimization?.ref?.[submitNum]?._id,
          threshold: submitOptimization?.threshold,
          optimizationParent: 'submitOptimization',
          optimized: submitOptimization?.choosen || false,
        },
        {
          sectionName: 'tech',
          sectionId: ourTechOptimization?.ref?.[ourTechNum]?._id,
          threshold: ourTechOptimization?.threshold,
          optimizationParent: 'ourTechOptimization',
          optimized: ourTechOptimization?.choosen || false,
        },
        {
          sectionName: 'trust',
          sectionId: trustOptimization?.ref?.[trustNum]?._id,
          threshold: trustOptimization?.threshold,
          optimizationParent: 'trustOptimization',
          optimized: trustOptimization?.choosen || false,
        },
        {
          sectionName: 'subscribe',
          sectionId: subscribeOptimization?.ref?.[subscribeNum]?._id,
          threshold: subscribeOptimization?.threshold,
          optimizationParent: 'subscribeOptimization',
          optimized: subscribeOptimization?.choosen || false,
        },
      ])
    );
  }, [heroOptimization?.ref?.[heroNum]?._id]);

  if (optimizationEnabled) {
    // set local storage
    if (!localStorage.getItem('userVisited')) {
      localStorage.setItem('userVisited', true);
      localStorage.setItem('hero', heroNum);
      localStorage.setItem('submit', submitNum);
      localStorage.setItem('ourTech', ourTechNum);
      localStorage.setItem('trust', trustNum);
      localStorage.setItem('subscribe', subscribeNum);
    }

    return {
      heroOptimization:
        heroOptimization?.choosen || heroOptimization?.ref[heroNum],
      submitOptimization:
        submitOptimization?.choosen || submitOptimization?.ref[submitNum],
      ourTechOptimization:
        ourTechOptimization?.choosen || ourTechOptimization?.ref[ourTechNum],
      trustOptimization:
        trustOptimization?.choosen || trustOptimization?.ref[trustNum],
      subscribeOptimization:
        subscribeOptimization?.choosen ||
        subscribeOptimization?.ref[subscribeNum],
    };
  }
  return false;
};
export default usePageOptimization;
