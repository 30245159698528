import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PAGE_QUERY } from '../queries';
import client from '../sanity';

const initialState = {
  data: [],
  loading: false,
};

export const fetchPages = createAsyncThunk('/api/sanity/pages', async (id) => {
  const sanity = await client.fetch(PAGE_QUERY, {});
  return [sanity.find((page) => page?.pageId === id)];
});

export const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPages.pending]: (state) => {
      state.loading = true;
    },
    [fetchPages.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchPages.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default templateSlice.reducer;
