import { configureStore } from '@reduxjs/toolkit';
import dynamicContents from './dynamicContents';
import questionsSlicer from './questionsSlicer';
// eslint-disable-next-line import/no-named-as-default
import templateSlice from './templateSlice';

const store = configureStore({
  reducer: {
    template: templateSlice,
    questions: questionsSlicer,
    dynamicContent: dynamicContents,
  },
});

export default store;
