const sanityClient = require('@sanity/client');
// Sanity Settings
const client = sanityClient({
  projectId: 't12dogkx',
  dataset: 'production',
  apiVersion: '2021-10-21', // use current UTC date - see "specifying API version"!
  token:
    'skIB3SQGCdszbgc04yAIBE6sCeCYkBeg2KWv6Ez68cbYX8BUTPKXOpl6j935hTiMo4kT009lQdjm4C6q9QKbwGCeDJySKbSxhmRPFnVetpcnLYCM8gwqQhCkwTbqLpvgs4tq9s1C0zRIx7ezLaFo97MGqDjl7ek9OLRYgtt66OsHwIOhhk0h',
  useCdn: true, // `false` if you want to ensure fresh data
});

export default client;
