const axios = require('axios');

const url = process.env.REACT_APP_OPTIMIZATION_API;
const sendOptimizationRecord = async ({
  visitorIP = '0',
  pageId,
  sectionId,
  outcomeScore,
  threshold,
  optimizationTag,
  sectionName,
  pageKey,
}) => {
  try {
    const post = await axios.post(url, {
      visitorIP,
      pageId,
      sectionId,
      outcomeScore,
      threshold,
      optimizationTag,
      sectionName,
      pageKey,
    });
    return post.data;
  } catch (error) {
    return error;
  }
};

export default sendOptimizationRecord;
