/* eslint-disable no-underscore-dangle */
import { useSelector } from 'react-redux';
import Cookies from 'universal-cookie';

const usePageInfo = () => {
  const cookies = new Cookies();
  const State = useSelector((state) => state?.template?.data[0]);
  return {
    user_Email: cookies.get('email') || '',
    template_Name: State?.templateName,
    page_Id: State?.pageId,
    pageKey: State._id,
    partner_Id: State?.partnerId,
  };
};

export default usePageInfo;
