/* eslint-disable no-underscore-dangle */
import { useSelector } from 'react-redux';
import sendOptimizationRecord from '../autoOptimization';

const useOptimizationSettings = () => {
  const state = useSelector((s) => s);
  const template = state?.template?.data[0];
  const { sectionIds } = state.dynamicContent;
  let outcomeScore;

  const sendEvent = ({ type, sectionName }) => {
    if (type === 'sectionVisit') {
      outcomeScore = 1;
    }

    if (type === 'cta') {
      outcomeScore = 10;
    }

    if (type === 'engage') {
      outcomeScore = 1;
    }

    const Section = sectionIds.find((s) => s.sectionName === sectionName);
    // !localStorage.getItem('userVisited')
    if (template?.optimization && !Section?.optimized) {
      sendOptimizationRecord({
        pageId: template.pageId,
        sectionId: Section?.sectionId,
        outcomeScore,
        threshold: Section?.threshold || 100,
        optimizationTag: type,
        sectionName: Section?.optimizationParent,
        pageKey: template._id,
      });
    }
  };
  return {
    sendEvent,
  };
};

export default useOptimizationSettings;
