import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import useStateTool from './Hooks/useStateTool';
import Home from './Pages/Home';
import { fetchDynamic } from './Redux/dynamicContents';
import { fetchPages } from './Redux/templateSlice';
import GlobalStyle from './Styles/Globals';
import useConfig from './Hooks/useConfig';
import usePageOptimization from './Hooks/usePageOptimization';

function App() {
  const dispatch = useDispatch();
  const { state } = useStateTool();
  const template = state?.template?.data[0];
  const loading = state?.template?.loading;
  const location = useLocation();
  const partnerId = location?.search?.replace('?id=', '') || String(0);
  const config = useConfig();

  useEffect(() => {
    dispatch(fetchPages(partnerId));
    dispatch(fetchDynamic());
  }, []);

  // Optimization
  const memoizedCallback = useCallback(() => usePageOptimization(), []);
  const optimization = memoizedCallback();

  return (
    <>
      {!loading && template && (
        <Helmet>
          <title>{config.title}</title>
          <meta name='description' content={config.description} />
          <link rel='icon' href={config.favicon} />
        </Helmet>
      )}
      <GlobalStyle />
      {template && !loading ? (
        <Routes>
          <Route path='/' element={<Home optimization={optimization} />} />
        </Routes>
      ) : (
        ''
      )}
    </>
  );
}

export default App;
