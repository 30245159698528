import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tags: [],
  completed: false,
};

export const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    setTags: (state, action) => {
      state.tags = [...state.tags, ...action.payload];
    },
    setStatus: (state) => {
      state.completed = true;
    },
  },
});

export default questionsSlice.reducer;
export const { setTags, setStatus } = questionsSlice.actions;
