import { useSelector } from 'react-redux';

const useConfig = () => {
  const STATE = useSelector((state) => state?.template?.data[0]);

  return {
    msgSubmit: STATE?.config?.msgSubmit,
    msgSubmitError: STATE?.config?.msgSubmitError,
    msgSubscribe: STATE?.config?.msgSubscribe,
    msgSubscribeError: STATE?.config?.msgSubscribeError,
    description: STATE?.config?.description,
    title: STATE?.config?.title,
    favicon: STATE?.config?.favicon?.asset?.url,
  };
};

export default useConfig;
