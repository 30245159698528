import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CONTEXT_QUERY } from '../queries';
import client from '../sanity';

const initialState = {
  contexts: [],
  loading: false,
  sectionIds: [],
};

export const fetchDynamic = createAsyncThunk(
  '/api/sanity/dynamics',
  async () => {
    const sanity = await client.fetch(CONTEXT_QUERY, {});
    return sanity;
  }
);

const dynamicContent = createSlice({
  name: 'dynamicContent',
  initialState,
  reducers: {
    sectionIds: (state, action) => {
      state.sectionIds = action.payload;
    },
  },
  extraReducers: {
    [fetchDynamic.pending]: (state) => {
      state.loading = true;
    },
    [fetchDynamic.fulfilled]: (state, action) => {
      state.contexts = action.payload;
      state.loading = false;
    },
    [fetchDynamic.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { sectionIds } = dynamicContent.actions;
export default dynamicContent.reducer;
