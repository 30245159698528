/* eslint-disable react/prop-types */
import React, { Suspense, useEffect, useState } from 'react';
import useScrollPosition from '../Hooks/useScroll';
import woopraHandler from '../woopra';
import usePageInfo from '../Hooks/usePageInfo';
import useSendEvent from '../Hooks/useSendEvent';
import useOptimizationSettings from '../Hooks/useOptimizationSettings';

const OurTech = React.lazy(() => import('../Components/OurTech'));
const Hero = React.lazy(() => import('../Components/Hero'));
const SubmitSection = React.lazy(() => import('../Components/SubmitSection'));
const Subscribe = React.lazy(() => import('../Components/Subscribe'));
const Trust = React.lazy(() => import('../Components/Trust'));

function Home({ optimization }) {
  const { monitoringService } = useSendEvent();
  const scroll = useScrollPosition();
  const [dom, setDom] = useState([]);
  const credentials = usePageInfo();
  const optimizationHandler = useOptimizationSettings();

  const scrollHandler = (section, sectionName) => {
    // Track section visit
    if (dom.includes(sectionName)) return;

    if (scroll > section) {
      woopraHandler('section_visit', { section: sectionName, ...credentials });
      monitoringService({
        eventId: 'cognni_landing_section_visit',
        data: { section: sectionName, ...credentials },
      });

      setDom((prev) => [...prev, sectionName]);
    }
  };

  useEffect(() => {
    // Send optimization event on scroll to section
    if (dom[dom.length - 2]) {
      optimizationHandler.sendEvent({
        type: 'sectionVisit',
        sectionName: dom[dom.length - 2],
      });
    }
  }, [dom]);

  useEffect(() => {
    const heroHeight = document.getElementById('hero')?.offsetTop;
    const submit = document.getElementById('submit')?.offsetTop;
    const tech = document.getElementById('tech')?.offsetTop;
    const trust = document.getElementById('trust')?.offsetTop;
    const subscribe = document.getElementById('subscribe')?.offsetTop;

    scrollHandler(heroHeight, 'hero');
    scrollHandler(submit, 'submit');
    scrollHandler(tech, 'tech');
    scrollHandler(trust, 'trust');
    scrollHandler(subscribe, 'subscribe');
  }, [scroll]);

  useEffect(() => {
    monitoringService({
      eventId: 'cognni_landing_page_view',
    });
  }, []);

  return (
    <div className='hero_enter_anim'>
      <Suspense>
        <Hero
          optimizationHandler={optimizationHandler}
          heroOptimization={optimization?.heroOptimization}
        />
        <SubmitSection
          optimizationHandler={optimizationHandler}
          submitOptimization={optimization?.submitOptimization}
        />
        <OurTech
          optimizationHandler={optimizationHandler}
          ourTechOptimization={optimization?.ourTechOptimization}
        />
        <Trust
          optimizationHandler={optimizationHandler}
          trustOptimization={optimization?.trustOptimization}
        />
        <Subscribe
          optimizationHandler={optimizationHandler}
          subscribeOptimization={optimization?.subscribeOptimization}
        />
      </Suspense>
    </div>
  );
}

export default Home;
