import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: "Gilmer Regular", sans-serif;
    margin: 0;
    outline: 0;
    padding: 0;
  }
  html {
    font-size: 62.5%;
  }
  body {
    font-size: 1.6rem;
  }
  a {
      color: #008ffb;
      cursor: pointer;
      text-decoration: none;
    }

    // React animations
.slide-exit {
  transform: translate(0%, 0%);
  position:absolute;
  width:482px;
  background: #ffffff;
  padding:0rem 1rem;

}
.slide-exit-active {
  transform: translate(300%, 0%);
  transition: transform 2000ms ease-in-out;
}


.slide-group {
  display: grid;
  position:relative;
  height:100%;
  align-items:center;
}

.hero_enter_anim {
  animation: enter 1s ease-in-out;
}

@keyframes enter {
  from { opacity: 0; }
  to { opacity: 1; }
}

`;

export default GlobalStyle;
